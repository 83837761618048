<template>
<v-container>
  <v-row class="d-flex justify-space-between align-center my-4">
    <div class="time-fb">
      {{this.curTime}}
    </div>
    <div class="feed-action-tag d-flex justify-end align-center">
      <v-menu :close-on-content-click="false" v-model="labelMenu" offset-x offset-y>
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-btn max-height="25" max-width="25" fab small color="grey">
            <v-icon small color="green">mdi-plus</v-icon>
          </v-btn> -->
          <div class="action-tag summary_intercom" v-bind="attrs" v-on="on">
            <v-icon class="message_icon pr-1" style="font-size:18px;">mdi-tag-outline</v-icon>
            <span>标签</span>
          </div>
        </template>
        <LabelForm @pick="addItemLabel" />
      </v-menu>

      <!-- <div class="mx-1">|</div>
      <div class="action-tag summary_intercom">
        <v-icon class="message_icon pr-1" style="font-size:18px;">mdi-currency-usd</v-icon>
        <span>打赏</span>
      </div> -->

      <div class="mx-1">|</div>
      <div class="action-tag summary_intercom" @click="onFavor" :style="{color:favorChk?'#ff9800':''}">
        <v-icon class="message_icon pr-1" style="font-size:18px;" :style="{color:favorChk?'#ff9800':''}">
          {{!favorChk?'mdi-heart-outline':'mdi-heart'}}
        </v-icon>
        <span v-html="!favorChk?'收藏':'取消收藏'"></span>
      </div>
      <div class="mx-1">|</div>
      <div class="action-tag summary_intercom" @click="displayAddIntercom">
        <v-icon class="message_icon pr-1" style="font-size:18px;">mdi-message-text-outline</v-icon>
        <span>回复</span>
      </div>
      <div class="mx-1">|</div>
      <v-menu open-on-hover top offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div @click="pickThumb(0)" class="action-tag summary_intercom" v-bind="attrs" v-on="on">
            <div v-if="!thumbUp">
              <v-icon class="message_icon pr-1" style="font-size:18px;">mdi-thumb-up-outline</v-icon>
              <span>点赞 {{thumbCnt?thumbCnt:''}}</span>
            </div>
            <div v-else class="d-flex align-center">
              <v-img v-if="thumbUp" :src="images.thumbs[thumbUp-1]" width="30"></v-img>
              <span>已赞 {{thumbCnt?thumbCnt:''}}</span>
            </div>
          </div>
        </template>

        <v-card width="360" height="80" left close-on-content-click class="d-flex justify-space-between">
          <v-card v-for="n in 6" class="thumb-item" style="text-align:center;" elevation="0" @click="pickThumb(n)">
            <v-img :src="images.thumbs[n-1]" width="50"></v-img>
            <span>{{thumbLabel[n-1]}}</span>
          </v-card>
        </v-card>

      </v-menu>
    </div>
  </v-row>
  <v-row>
    <v-chip @click:close="removeLabel(id)" close v-if="labels.length > 0" class="ma-1" v-for="(id, i) in labelIds" :key="i">
      <div :class="`${colors[getLabel(id).tag_color]} rounded-circle pa-1`"></div><span class="ml-2 text-caption">{{ getLabel(id).tag_name }}</span>
    </v-chip>
  </v-row>
  <v-row v-if="!!rp_id" d-flex class="width100 summary_intercom_p_0">
    <!-- <v-layout d-flex align-center justify-center class="width100 m_0"> -->
    <!-- <AddSummaryIntercom :rp_id="rp_id" :displayEditWindow="showIntercomWindow" @showEditWindow="" /> -->
    <!-- </v-layout> -->
    <Intercom :ic_obj="{
        ic_parent: this.rp_id,
        ic_parent_type: this.rp_type,
    }" :goal="this.goal" />
  </v-row>
</v-container>
</template>

<script>
import Images from '@/constants/image.js'

import {
  mapGetters,
  mapActions
} from 'vuex'

export default {
  name: 'LikingComponent',
  props: ['curTime', 'rp_id', 'rp_type'],
  data() {
    return {
      images: Images,
      goal: true,
      labelMenu: false,
      likings: {},
      colors: [
        'red', 'pink', 'orange', 'green', 'blue', 'purple'
      ],
      thumbLabel: [
        '点赞', '加油', '666', '祝贺', '有见地', '比心',
      ],
      filterObj: {
        like_parent: this.rp_id,
        like_parent_type: this.rp_type,
      },
    }
  },
  created() {
    this.getLikings(this.filterObj).then(res => {
      if (res && res.length > 0) {
        this.likings = res[0];
      }
    });
  },
  watch: {
    rp_id: function (val) {
      this.filterObj = {
        like_parent: val,
        like_parent_type: this.rp_type,
      };
      this.getLikings(this.filterObj).then(res => {
        if (res && res.length > 0) {
          this.likings = res[0];
        }
      });
    }
  },
  components: {
    Intercom: () => import('@/components/common/Intercom/Intercom.vue'),
    LabelForm: () => import('@/components/itemDetail/shared/LabelForm.vue'),
    // AddSummaryIntercom: () => import('@/components/summary/AddSummaryIntercom.vue'),
  },
  computed: {
    ...mapGetters('label', ['labels']),
    ...mapGetters('auth', ['authUser']),
    thumbCnt: {
      get: function () {
        if (this.likings.like_thumb) {
          return this.likings.like_thumb.split(",").length;
        } else {
          return 0;
        }
      }
    },
    thumbUp: {
      get: function () {
        if (this.likings.like_thumb) {
          let ep = new RegExp("^" + this.authUser.id + ":.|," + this.authUser.id + ":.");
          let ep1 = new RegExp(".*:(.)");
          let matchs = this.likings.like_thumb.match(ep);
          if (matchs) {
            return matchs[0].replace(ep1, "$1") * 1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      },
      set: function (val) {
        if (!this.likings.like_thumb) {
          this.likings.like_thumb = "";
        }
        let ep = new RegExp("^" + this.authUser.id + ":.|," + this.authUser.id + ":.");
        let res = this.likings.like_thumb.replace(ep, "");
        if (val) {
          res += "," + this.authUser.id + ":" + val;
        }
        this.updateLiking({
          like_id: this.likings.like_id,
          like_thumb: res.replace(/^[,]+|[,]+$/g, ""),
        }).then(res1 => {
          if (res1) {
            this.likings.like_thumb = res.replace(/^[,]+|[,]+$/g, "");
          }
        })

      }
    },
    favorChk: {
      get: function () {
        if (this.likings.like_favor) {
          return (',' + this.likings.like_favor + ',').includes(',' + this.authUser.id + ',');
        } else {
          return false;
        }
      },
      set: function (val) {
        if (val) {
          let favors = (this.likings.like_favor + ',' + this.authUser.id).replace(/^[,]+|[,]+$/g, "");
          this.updateLiking({
            like_id: this.likings.like_id,
            like_favor: favors
          }).then(res => {
            if (res) {
              this.likings.like_favor = favors;
            }
          })
        } else {
          let re = new RegExp("^" + this.authUser.id + ",|," + this.authUser.id + "|^" + this.authUser.id + "$", "g");
          let favors = this.likings.like_favor.replace(re, '');
          this.updateLiking({
            like_id: this.likings.like_id,
            like_favor: favors
          }).then(res => {
            if (res) {
              this.likings.like_favor = favors;
            }
          })
        }
      },
    },
    labelIds: {
      get: function () {
        if (this.likings.like_tag) {
          const ids = this.likings.like_tag.split(',')
          return ids.filter(res => {
            return this.getLabel(res);
          })
        } else {
          return [];
        }
      },
      set: function (newArr) {
        this.updateLiking({
          like_id: this.likings.like_id,
          like_tag: newArr.join()
        }).then(res => {
          if (res) {
            this.likings.like_tag = newArr.join();
          }
        })
      },
    },
  },
  methods: {
    ...mapActions('liking', ['getLikings', 'updateLiking']),
    pickThumb(thumbNum) {
      if (thumbNum) {
        this.thumbUp = thumbNum;
      } else {
        if (this.thumbUp) {
          this.thumbUp = 0;
        } else {
          this.thumbUp = 1;
        }
      }
    },
    getLabel(id) {
      const index = this.labels.findIndex(label => label.tag_id == id)
      if (index == -1) {
        return null;
      }
      return this.labels[index]
    },
    onFavor() {
      this.favorChk = !this.favorChk;
    },
    removeLabel(id) {
      const index = this.labelIds.findIndex(labelid => labelid == id)
      if (index != -1) {
        this.labelIds.splice(index, 1);
        this.labelIds = this.labelIds;
      }
    },
    addItemLabel(e) {
      const index = this.labelIds.findIndex(labelid => labelid == e)
      if (index == -1) {
        this.labelIds.push(e);
        this.labelIds = this.labelIds;
      }
    },
    displayAddIntercom() {
      this.goal = !this.goal;
    },
  }
}
</script>

<style scoped>
.v-image:hover {
  width: 60px;
}

/* .action-tag:hover{

color: #1587d9;
}

.action-tag:hover v-icon{

color: #1587d9;
} */
</style>
